import { Component, Vue } from 'vue-property-decorator';


interface IItem {
    date: Date;
    value: number;
}

const initial: IItem[] = [
    {
        date: new Date(2019, 11),
        value: 4
    },
    {
        date: new Date(2018, 10),
        value: 13
    }
];


@Component
export default class CSelectTest extends Vue {
    private items = initial;
    private item: IItem | null = null;
}