















































import Class from './c-select-test';
export default Class;
