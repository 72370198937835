import { Component, Vue } from 'vue-property-decorator';

import { ModalHideEvent } from '@/components';
import lorem from '@/utils/lorem';


@Component
export default class CModalTest extends Vue {
    private visible = false;
    private lorem = lorem.substr(0, 200);
    private loremCount = 1;

    private get lorems(): string[] {
        const result: string[] = [];

        for (let i = 0; i < this.loremCount; i++) {
            result.push(this.lorem);
        }

        return result;
    }

    private prevented = false;

    private headerDisabled = false;
    private footerDisabled = false;

    private toolbar = false;
    private header = false;
    private title = false;
    private closeButton = false;
    private closeButtonText = false;
    private footer = false;
    private okButton = false;
    private cancelButton = false;

    private onHide(ev: ModalHideEvent) {
        // console.log('hide');
        if ((ev.reason !== 'ok-click') && (ev.reason !== 'overlay-click')) {
            ev.preventDefault();
        }
    }

    private onHidden() {
        // console.log('hidden');
    }
}