var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"btn-button",on:{"click":function($event){_vm.visible = !_vm.visible}}},[_vm._v(_vm._s(_vm.visible ? 'Hide' : 'Show'))]),_c('c-select',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_vm._v(_vm._s(_vm.appearance === null ? 'null' : _vm.appearance))]},proxy:true}]),model:{value:(_vm.appearance),callback:function ($$v) {_vm.appearance=$$v},expression:"appearance"}},_vm._l((_vm.appearances),function(item){return _c('c-select-item',{key:'appearance-' + item,attrs:{"value":item}},[_vm._v(_vm._s(item === null ? 'null' : item))])}),1),_c('hr'),_c('button',{staticClass:"btn-button"},[(_vm.visible)?_c('c-loading',{attrs:{"appearance":_vm.appearance}}):_vm._e(),_c('span',[_vm._v("Button with loading")])],1),_c('div',{staticStyle:{"height":"30px"}},[_c('div',{style:({
                'border': '1px dotted grey',
                'width': 'max-content',
                'height': 'max-content'
            })},[(_vm.visible)?_c('c-loading',{attrs:{"appearance":_vm.appearance}}):_vm._e()],1)]),_c('div',{style:({
            'margin': '8px',
            'padding': '16px',
            'overflow': 'visible',
            'border-radius': '4px',
            'border-top-left-radius': '16px',
            'border': '1px dotted grey'
        })},[_c('button',{staticClass:"btn-button"},[_vm._v("First button")]),_c('div',[_vm._v("Line 1")]),_c('div',[_vm._v("Line 2")]),_c('div',[_vm._v("Line 3")]),_c('button',{staticClass:"btn-button"},[_vm._v("Last button")]),_c('button',{staticClass:"btn-button",style:({
                'position': 'absolute',
                'left': '50px',
                'top': '-10px'
            })},[_vm._v("Absolute position :: top")]),_c('button',{staticClass:"btn-button",style:({
                'position': 'absolute',
                'left': '-15px',
                'top': '50px'
            })},[_vm._v("Absolute position :: left")]),(_vm.visible)?_c('c-loading',{attrs:{"appearance":_vm.appearance,"overlay":""}}):_vm._e()],1),_c('div',{staticStyle:{"font-size":"30px","color":"black"}},[_c('c-icon',{attrs:{"icon":"folder-outline","svg":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }