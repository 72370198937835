

































import Class from './c-pagination-test';
export default Class;
