import { Component, Vue } from 'vue-property-decorator';


@Component
export default class CDatePickerTest extends Vue {
    private date: Date | null = new Date();
    private min: Date | null = null;
    private max: Date | null = null;
    private locales = ['en', 'ru'];
    private locale = 'en';

    private onMinChange(ev: Event) {
        const target = ev.target;
        if (target instanceof HTMLInputElement) {
            this.min = target.valueAsDate;
        }
    }

    private onDateChange(ev: Event) {
        const target = ev.target;
        if (target instanceof HTMLInputElement) {
            this.date = target.valueAsDate;
        }
    }

    private onMaxChange(ev: Event) {
        const target = ev.target;
        if (target instanceof HTMLInputElement) {
            this.max = target.valueAsDate;
        }
    }
}