import { Component, Vue } from 'vue-property-decorator';


@Component
export default class Class extends Vue {
    private count = 0;

    private get items(): string[] {
        const result: string[] = [];

        for (let i = 0; i < this.count; i++) {
            result.push(`Item #${i + 1}`);
        }

        return result;
    }

    private page = 1;

    private beforeUpdate() {
        if (typeof this.page === 'string') {
            this.page = parseFloat(this.page);
        }
        if (typeof this.perPage === 'string') {
            this.perPage = parseFloat(this.perPage);
        }
    }

    private perPage = 5;

    private get offset(): number {
        const result = (this.page - 1) * this.perPage;

        if (result < 0) {
            return 0;
        }

        return result;
    }

    private get shownItems(): string[] {
        const result: string[] = [];

        for (let i = this.offset; i < this.offset + this.perPage; i++) {
            if (this.items.length > i) {
                result.push(this.items[i]);
            }
        }

        return result;
    }

    private onPaginationInput() {
        // console.log('Input emitted');
    }
}