

































































































































import Class from './c-modal-test';
export default Class;
