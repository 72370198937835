































































































import Class from './c-loading-test';
export default Class;
