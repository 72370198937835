import { Component, Vue } from 'vue-property-decorator';


@Component
export default class CCalendarTest extends Vue {
    private date = new Date();
    private showData = false;

    private randomCount(): number {
        return Math.ceil(Math.random() * 100);
    }

    private get locale(): string {
        return this.$i18n.locale;
    }

    private get format(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });
    }
}